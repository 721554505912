/* Logo */

.logo {
    margin-left: 20px;
    margin-right: 20px;
}

/* Footer */

#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

main {
    flex: 1 0 auto;
}

/* Messages */

.error {
    background: red;
    color: white
}

.success {
    background: green;
    color: white
}

.info {
    background: darkgrey;
    color: white
}

/* Landing Page */

.landing {
    position: relative;
    background: url('./img/viehmarkt.jpg') no-repeat center center/cover;
    height: 100vh;
}

.landing-inner {
    color: #fff;
    height: 100%;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* Confirmation Page */

.lottery-ticket {
    font-weight: bold;
}

/* Overlay */

.dark-overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Spinner */

.spinner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 40%;
}

/* Search */

.searchform {
    margin-top: 40px;
    margin-bottom: 40px;
}

/* Buttons */

.btn-margin {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
}

/* Chips */

.skill-chips {
    margin-top: 1rem;
}

/* Text Sizes*/

.landing-heading {
    font-size: 4rem;
    line-height: 1.2;
    margin-bottom: 1rem;
}

.x-large {
    font-size: 4rem;
    line-height: 1.2;
    margin-bottom: 1rem;
}

.large {
    font-size: 3rem;
    line-height: 1.2;
    margin-bottom: 1rem;
}

.lead {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

/* Fonts */

.comic {
    font-family: "Comic Sans MS", cursive, sans-serif;
}

/* Text colors */

.ride-title {
    background-color: #03a9f4;
}

/* Social Networks */

i.fa-globe {
    color: #03a9f4
}

i.fa-globe.light {
    color: #f4f4f4
}

i.fa-linkedin {
    color: #0077b5;
}

i.fa-linkedin.light {
    color: #f4f4f4
}

i.fa-xing {
    color: #006567;
}

i.fa-xing.light {
    color: #f4f4f4
}

i.fa-facebook {
    color: #3b5998;
}

i.fa-facebook.light {
    color: #f4f4f4
}

i.fa-twitter {
    color: #38a1f3;
}

i.fa-twitter.light {
    color: #f4f4f4
}

i.fa-instagram {
    color: #3f729b;
}

i.fa-instagram.light {
    color: #f4f4f4
}

i.fa-youtube {
    color: #c4302b;
}

i.fa-youtube.light {
    color: #f4f4f4
}

/* Mobile */

@media (max-width: 700px) {
    .hide-sm {
        display: none;
    }
    .landing-heading {
        font-size: 2.5rem;
        line-height: 1.2;
        margin-bottom: 1rem;
    }
}